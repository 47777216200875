import { faAngleRight as lightAngleRight } from "@jewlr/pro-light-svg-icons/faAngleRight"
import { Box, Flex, FontAwesomeIcon, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const Title = styled(Typography).attrs((props) => ({
  as: "h2",
  fontSize: { _: "30px", tablet: "34px" },
  fontWeight: "300",
  lineHeight: 1.2,
  mb: { _: 2, desktop: 4 },
  ...props.theme.landing.text.title,
  ...props,
}))``

const Description = styled(Typography).attrs((props) => ({
  fontSize: { _: "16px", tablet: "18px" },
  letterSpacing: "0.5px",
  light: true,
  mb: 3,
  ...props.theme.landing.text.description,
  ...props,
}))``

const BannerHeading = styled(Typography).attrs((props) => ({
  fontSize: { _: "32px", tablet: "54px" },
  fontWeight: "700",
  lineHeight: { _: "36px", tablet: 1.2 },
  mb: { _: "10px", tablet: 4 },
  ...props,
}))``

const BannerDesc = styled(Typography).attrs((props) => ({
  fontSize: { _: "18px", tablet: "24px" },
  lineHeight: { _: "20px", tablet: 1.2 },
  mb: { _: "10px", tablet: 4 },
  ...props,
}))``

const HomepageHeading = styled(Typography).attrs((props) => ({
  as: "h2",
  fontSize: { _: "24px", tablet: "30px" },
  fontWeight: "700",
  lineHeight: "36px",
  mb: { _: "4px", tablet: 1 },
  ...props,
}))``

const HomepageDesc = styled(Typography).attrs((props) => ({
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "20px",
  ...props,
}))``

const SectionContainer = styled(Flex).attrs((props) => ({
  alignItems: "flex-end",
  borderBottom: "1px solid #9B9B9B",
  justifyContent: "space-between",
  mb: 3,
  p: { _: "0 0 8px", tablet: "0 16px 8px" },
  ...props,
}))``

const SectionLink = styled(Link)`
  align-items: center;
  display: inline-flex;
  font-size: 18px;
`

const TitleLine = styled(Typography).attrs((props) => ({
  fontSize: { _: "20px", tablet: "30px" },
  fontWeight: "normal",
  letterSpacing: "2.5px",
  mb: 2,
  pb: 2,
  ...props,
}))`
  background-image: linear-gradient(
    transparent,
    ${(props) => props.lineColor || "#313131"}
  );
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 56px 1px;
  text-transform: uppercase;
`

const CapTitle = styled(Typography).attrs((props) => ({
  fontSize: { _: "24px", tablet: "30px" },
  fontWeight: "500",
  lineHeight: { _: "26px", tablet: "32px" },
  mb: 2,
  ...props,
}))`
  text-transform: uppercase;
`

const FeaturedTitle = styled(Typography).attrs((props) => ({
  as: "h3",
  color: props.theme.colors.white,
  fontSize: { _: "24px", tablet: "30px" },
  fontWeight: "500",
  letterSpacing: "1px",
  lineHeight: { _: "26px", desktop: "30px" },
  mb: 1,
  ...props,
}))``

const FeaturedSale = styled(Typography).attrs((props) => ({
  color: props.theme.colors.white,
  fontSize: { _: "16px", desktop: "18px" },
  fontWeight: "500",
  lineHeight: { _: "18px", desktop: "20px" },
  ...props,
}))`
  span {
    font-size: 40px;
    font-weight: 500;
    line-height: 38px;
    ${(props) => props.theme.mediaQueries.widescreen`
      font-size: 48px;
      line-height: 42px;
    `}
  }
`

const SkuIcon = styled(Box).attrs((props) => ({
  bg: "grey.20",
  border: props.vermeil
    ? "12px solid #F8E7AD"
    : `6px solid ${props.theme.colors.white}`,
  boxShadow: `0 0 0 1px ${props.theme.colors.grey[65]}`,
  height: "110px",
  width: "110px",
  ...props,
}))`
  border-radius: 50%;
`

const Text = ({ firstElement, style, text, linkClickData }) => {
  if (text.type === "title") {
    return (
      <Title
        as={firstElement ? "h1" : "h2"}
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "banner_heading") {
    return (
      <BannerHeading
        as={firstElement ? "h1" : "h2"}
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "banner_description") {
    return (
      <BannerDesc
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "homepage_heading") {
    return (
      <HomepageHeading
        as={firstElement ? "h1" : "h2"}
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "homepage_description") {
    return (
      <HomepageDesc
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "title_line") {
    return (
      <TitleLine
        as={firstElement ? "h1" : "h2"}
        dangerouslySetInnerHTML={{ __html: text.text }}
        lineColor={text.style?.lineColor}
        {...text.style}
      />
    )
  }
  if (text.type === "cap_title") {
    return (
      <CapTitle
        as={firstElement ? "h1" : "h2"}
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "description") {
    return (
      <Description
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "section_heading") {
    return (
      <SectionContainer {...style}>
        <Typography
          as="h2"
          display="inline-block"
          fontSize={18}
          fontWeight="normal"
          letterSpacing="1px"
          {...text.style}
        >
          {text.text.toUpperCase()}
        </Typography>
        {text.url && (
          <SectionLink data-lc={linkClickData} to={text.url}>
            Shop All{" "}
            <FontAwesomeIcon fontSize={18} icon={lightAngleRight} ml={1} />{" "}
          </SectionLink>
        )}
      </SectionContainer>
    )
  }
  if (text.type === "featured_heading") {
    return (
      <FeaturedTitle
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "featured_sale") {
    return (
      <FeaturedSale
        dangerouslySetInnerHTML={{ __html: text.text }}
        {...text.style}
      />
    )
  }
  if (text.type === "sku_icon") {
    const look = text.text?.toLowerCase() || ""
    const sku = look.replace(/[0-9]/g, "")
    const vermeil = ["sskyp", "ss18kyp", "vermy"].includes(sku)
    return <SkuIcon bg={`skus.${sku}`} vermeil={vermeil} {...text.style} />
  }
  return (
    <Typography dangerouslySetInnerHTML={{ __html: text.text }} {...style} />
  )
}

Text.propTypes = {
  firstElement: PropTypes.bool,
  linkClickData: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.object,
}

export default Text
